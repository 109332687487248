import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { CHART_PROPS_REDESIGN } from 'site-modules/shared/constants/price-checker';

const STROKE_WITH_LABEL_RATE = 4;
const CUSTOM_PRICE_LABEL_TEXT_HEIGHT = 16;
const CUSTOM_LABEL_TEXT_HEIGHT = 14;
const CHART_WIDTH_EXTRA = 8;
const ARROW_BORDER_RADIUS = 1;

// TODO: Leave only this PricingChart component after all charts design updates. Move out of promo directory
export const PricingChart = ({
  className,
  isMobile,
  dataPoints,
  columnWidth,
  columnPadding,
  yRate,
  yStartingPoint,
  strokeLength,
  customLabels,
  customPriceLabels,
  selectedPriceIndex,
  selectedPricePercent,
  overlayConfig,
  animationBegin,
  hasAnimation,
  arrowSize,
  arrowLineTopSpace,
  arrowLineTopPosition,
  idPrefix,
  isSample,
  hideNoIndexArrow,
  hideArrowLine,
  customChartHeight,
  dealerPriceIndex,
  dealerPrice,
  showPolyline,
  customLabelClassName,
  customPriceLabelClassName,
  svgRole,
  isStepGradient,
  creativeId,
  trackingId,
  ariaDescribedBy,
  pricingChartDescription,
}) => {
  const device = isMobile ? 'mobile' : 'desktop';
  const columnFullWidth = columnWidth[device]; // with stroke
  const columnInnerWidth = columnFullWidth - 2; // without stroke
  const chartWidth =
    columnFullWidth * dataPoints.length +
    columnPadding * (dataPoints.length - 1) +
    (hideNoIndexArrow ? 0 : CHART_WIDTH_EXTRA);
  const singleColSpace = columnFullWidth + columnPadding;

  const priceLabelHeight = get(customPriceLabels, 'length')
    ? strokeLength[device] * STROKE_WITH_LABEL_RATE + CUSTOM_PRICE_LABEL_TEXT_HEIGHT + CUSTOM_PRICE_LABEL_TEXT_HEIGHT
    : 0;
  const customLabelHeight =
    get(customLabels, 'length') && !priceLabelHeight ? strokeLength[device] * 2.5 + CUSTOM_LABEL_TEXT_HEIGHT / 2 : 0;

  const computedHeight =
    yStartingPoint + // graph
    priceLabelHeight + // price label: stroke + spacing + label text
    customLabelHeight;

  const hasSelectedIndex = selectedPriceIndex !== null;
  const hasSelectedPricePercent = selectedPricePercent !== null;

  // Arrow params
  const halfArrowSize = arrowSize / 2;
  const selectedIndexXStartCord = hasSelectedPricePercent
    ? (chartWidth / 100) * selectedPricePercent
    : selectedPriceIndex * singleColSpace;
  const arrowX1 = hasSelectedPricePercent
    ? Math.min(Math.max(selectedIndexXStartCord, halfArrowSize), chartWidth - halfArrowSize)
    : selectedIndexXStartCord + columnFullWidth / 2;
  const arrowX2 = chartWidth * arrowLineTopPosition;
  const curveWidth = Math.min(5, Math.abs(arrowX1 - arrowX2) / 2);
  const firstCurveShift = arrowX1 < arrowX2 ? curveWidth : -curveWidth;
  const secondCurveShift = arrowX1 < arrowX2 ? -curveWidth : curveWidth;
  const columnsEdge = singleColSpace * dataPoints.length;

  // Overlay
  const currentOverlays = hasSelectedIndex
    ? overlayConfig &&
      overlayConfig.overlaysProps.filter(
        ({ startPos, endPos, alwaysShow }) =>
          alwaysShow || (selectedPriceIndex >= startPos && selectedPriceIndex < endPos)
      )
    : overlayConfig && overlayConfig.overlaysProps.filter(({ isDefault, alwaysShow }) => alwaysShow || isDefault);
  const OverlayComponent = get(overlayConfig, 'component');
  const isOverlayOverlapArrow = !!get(overlayConfig, 'isOverlapArrow');
  const overlay = !!get(currentOverlays, 'length') && (
    <g className="overlays">
      {currentOverlays.map(currentOverlay => (
        <OverlayComponent
          key={`overlay-${currentOverlay.startPos}`}
          chartProps={{ idPrefix, singleColSpace, yStartingPoint }}
          {...currentOverlay}
        />
      ))}
    </g>
  );

  return (
    <div
      className={classnames('pricing-chart', className)}
      data-tracking-parent={creativeId}
      data-tracking-id={trackingId}
    >
      <svg
        width="100%"
        viewBox={`0 0 ${chartWidth} ${customChartHeight || computedHeight}`}
        style={{ maxWidth: chartWidth }}
        xmlns="http://www.w3.org/2000/svg"
        role={svgRole}
        aria-describedby={ariaDescribedBy}
        id={`${idPrefix}pricing-chart-svg`}
      >
        <title>{isSample ? 'Sample good price chart based on nearby sales' : 'Pricing chart'}</title>
        {pricingChartDescription && <desc>{pricingChartDescription}</desc>}
        <defs>
          <linearGradient
            id={`${idPrefix}graph-gradient`}
            x1="100%"
            y1="0%"
            x2={0}
            y2={0}
            gradientUnits="userSpaceOnUse"
          >
            {isStepGradient ? (
              <Fragment>
                <stop offset="0%" style={{ stopColor: 'rgba(255, 156, 76, 0.5)' }} />
                <stop offset="25%" style={{ stopColor: 'rgba(255, 156, 76, 0.5)' }} />
                <stop offset="25%" style={{ stopColor: 'rgba(88, 184, 131, 0.5)' }} />
                <stop offset="50%" style={{ stopColor: 'rgba(88, 184, 131, 0.5)' }} />
                <stop offset="50%" style={{ stopColor: 'rgba(22, 199, 223, 0.5)' }} />
                <stop offset="75%" style={{ stopColor: 'rgba(22, 199, 223, 0.5)' }} />
                <stop offset="75%" style={{ stopColor: 'rgba(78, 145, 245, 0.5)' }} />
                <stop offset="100%" style={{ stopColor: 'rgba(78, 145, 245, 0.5)' }} />
              </Fragment>
            ) : (
              <Fragment>
                <stop offset="10%" style={{ stopColor: 'rgba(255, 156, 76, 0.5)' }} />
                <stop offset="40%" style={{ stopColor: 'rgba(88, 184, 131, 0.5)' }} />
                <stop offset="65%" style={{ stopColor: 'rgba(22, 199, 223, 0.5)' }} />
                <stop offset="91%" style={{ stopColor: 'rgba(78, 145, 245, 0.5)' }} />
              </Fragment>
            )}
          </linearGradient>
          <filter id={`${idPrefix}arrow-shadow`}>
            <feDropShadow dx={0} dy={0} stdDeviation={1} floodColor="black" floodOpacity={0.5} />
          </filter>
        </defs>
        {/* X axis */}
        <line x1="-1%" y1={yStartingPoint} x2="101%" y2={yStartingPoint} stroke="#e6e6e6" />
        {isSample && (
          <text
            fill="#eef1f6" // $cool-gray-80
            textAnchor="middle"
            dominantBaseline="middle"
            x="46%"
            y="47%"
            className="sample-text font-weight-bold"
            stroke="#cbd4e1" // $cool-gray-70
            strokeWidth={0.5}
          >
            Sample
          </text>
        )}
        <g fill={`url(#${idPrefix}graph-gradient)`}>
          {dataPoints.map((y, index) => {
            const xVal = index * singleColSpace + 1;
            const yVal = y * yRate;

            // TODO: Rework animation points to be based on props
            const startY = CHART_PROPS_REDESIGN.DATA_POINTS.WITHOUT_DATA[index] * yRate;
            const endY = CHART_PROPS_REDESIGN.DATA_POINTS.WITH_DATA[index] * yRate;

            let labelEl = (
              <Fragment>
                {!!customPriceLabels &&
                  customPriceLabels
                    .filter(({ index: labelIndex }) => labelIndex === index)
                    .map(({ end, label }, customLabelIndex) => {
                      const key = `custom-${label}-${customLabelIndex}`;
                      const x = end ? xVal + columnFullWidth - 2 : xVal;
                      const y2 = label
                        ? yStartingPoint + strokeLength[device] * STROKE_WITH_LABEL_RATE
                        : yStartingPoint + strokeLength[device];

                      return (
                        <g key={key}>
                          <line x1={x} y1={yStartingPoint} x2={x} y2={y2} stroke="#94a2b8" />
                          {!!label && (
                            <text
                              fill="#2a3546"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              x={x}
                              y={y2 + CUSTOM_PRICE_LABEL_TEXT_HEIGHT}
                              className={customPriceLabelClassName}
                            >
                              {label}
                            </text>
                          )}
                        </g>
                      );
                    })}
              </Fragment>
            );

            const roundedBorderWithLinePart = `a2,2 0 0 0 -2,-2 h -${columnInnerWidth - 4} a2,2 0 0 0 -2,2`;
            if (dealerPriceIndex === index && showPolyline && dealerPrice) {
              const x1 = xVal + columnInnerWidth / 2;

              labelEl = (
                <Fragment>
                  {labelEl}
                  <g>
                    <polyline
                      points={`${x1},${yStartingPoint} ${x1 - 6},${yStartingPoint + 12} ${x1 + 6},${yStartingPoint +
                        12} ${x1},${yStartingPoint}`}
                      fill="#007ee5"
                      stroke="#007ee5"
                    />
                  </g>
                </Fragment>
              );
            }

            return (
              <g key={xVal}>
                <path
                  width={columnInnerWidth}
                  height={yVal}
                  x={xVal}
                  y={yVal}
                  d={`M ${xVal},${yStartingPoint} h ${columnInnerWidth} v -${
                    hasAnimation ? 0 : yVal
                  } ${roundedBorderWithLinePart} Z`}
                  stroke="#64748b"
                >
                  {hasAnimation && (
                    <animate
                      className="price-offer-graph-animation"
                      attributeName="d"
                      values={`M ${xVal},${yStartingPoint} h ${columnInnerWidth} v -0 ${roundedBorderWithLinePart} Z; M ${xVal},${yStartingPoint} h ${columnInnerWidth} v -${startY} ${roundedBorderWithLinePart} Z; M ${xVal},${yStartingPoint} h ${columnInnerWidth} v -${endY} ${roundedBorderWithLinePart} Z; M ${xVal},${yStartingPoint} h ${columnInnerWidth} v -${endY} ${roundedBorderWithLinePart} Z`}
                      keyTimes="0; 0.45; 0.75; 1"
                      repeatCount={1}
                      fill="freeze"
                      dur="3000ms"
                      begin={animationBegin}
                    />
                  )}
                </path>
                {labelEl}
              </g>
            );
          })}
        </g>

        {!isOverlayOverlapArrow && overlay}

        {hasSelectedIndex ? (
          <g>
            {!hideArrowLine && (
              <Fragment>
                {arrowX1 !== arrowX2 ? (
                  <path
                    fill="none"
                    stroke="#2a3546"
                    d={`M ${arrowX1} ${yStartingPoint} L ${arrowX1} ${arrowLineTopSpace +
                      5} Q ${arrowX1} ${arrowLineTopSpace} ${arrowX1 +
                      firstCurveShift} ${arrowLineTopSpace} L ${arrowX2 +
                      secondCurveShift} ${arrowLineTopSpace} Q ${arrowX2} ${arrowLineTopSpace} ${arrowX2} ${arrowLineTopSpace -
                      5} L ${arrowX2} 0`}
                  />
                ) : (
                  <polyline
                    points={`${arrowX1},${yStartingPoint} ${arrowX1},${arrowLineTopSpace} ${arrowX2},${arrowLineTopSpace} ${arrowX2},0`}
                    fill="none"
                    stroke="#2a3546"
                  />
                )}
              </Fragment>
            )}
            <path
              fill="#ffffff"
              stroke="#2a3546"
              strokeWidth={'1'}
              filter={`url(#${idPrefix}arrow-shadow)`}
              d={`M ${arrowX1 + ARROW_BORDER_RADIUS} ${yStartingPoint - ARROW_BORDER_RADIUS} L ${arrowX1 +
                halfArrowSize} ${yStartingPoint - arrowSize + ARROW_BORDER_RADIUS} Q ${arrowX1 +
                halfArrowSize} ${yStartingPoint - arrowSize} ${arrowX1 +
                halfArrowSize -
                ARROW_BORDER_RADIUS} ${yStartingPoint - arrowSize} L ${arrowX1 -
                halfArrowSize +
                ARROW_BORDER_RADIUS} ${yStartingPoint - arrowSize} Q ${arrowX1 - halfArrowSize} ${yStartingPoint -
                arrowSize} ${arrowX1 - halfArrowSize} ${yStartingPoint - arrowSize + ARROW_BORDER_RADIUS} L ${arrowX1 -
                ARROW_BORDER_RADIUS} ${yStartingPoint - ARROW_BORDER_RADIUS} Q ${arrowX1} ${yStartingPoint} ${arrowX1 +
                ARROW_BORDER_RADIUS} ${yStartingPoint - ARROW_BORDER_RADIUS}`}
            />
          </g>
        ) : (
          !hideNoIndexArrow && (
            <g>
              {/* the triangle at the far end of the x-axis when no price */}
              <polygon
                points={`${columnsEdge},${yStartingPoint} ${columnsEdge + halfArrowSize},${yStartingPoint -
                  arrowSize} ${columnsEdge - halfArrowSize},${yStartingPoint - arrowSize}`}
                fill="#ffffff"
                stroke="#999999"
                strokeWidth={'0.5'}
                filter={`url(#${idPrefix}arrow-shadow)`}
              />
            </g>
          )
        )}

        {isOverlayOverlapArrow && overlay}

        {!!customLabels &&
          customLabels.map(({ label, position, color }) => (
            <text
              key={label}
              fill={color || '#2a3546'}
              textAnchor="middle"
              dominantBaseline="middle"
              x={`${position}%`}
              y={yStartingPoint + strokeLength[device] * 2.5}
              className={customLabelClassName}
            >
              {label}
            </text>
          ))}
      </svg>
    </div>
  );
};

PricingChart.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  dataPoints: PropTypes.arrayOf(PropTypes.number), // Defines height of each column
  columnWidth: PropTypes.shape({
    mobile: PropTypes.number,
    desktop: PropTypes.number,
  }),
  columnPadding: PropTypes.number,
  strokeLength: PropTypes.shape({
    mobile: PropTypes.number,
    desktop: PropTypes.number,
  }),
  yRate: PropTypes.number,
  yStartingPoint: PropTypes.number,
  arrowSize: PropTypes.number,
  arrowLineTopSpace: PropTypes.number,
  arrowLineTopPosition: PropTypes.number, // [0..1]
  customLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      position: PropTypes.number, // Percents
    })
  ),
  customPriceLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      index: PropTypes.number,
      end: PropTypes.bool,
    })
  ),
  overlayConfig: PropTypes.shape({
    isOverlapArrow: PropTypes.bool,
    component: PropTypes.func,
    overlaysProps: PropTypes.arrayOf(PropTypes.shape({})), // must include startPos and endPos
  }),
  selectedPriceIndex: PropTypes.number,
  selectedPricePercent: PropTypes.number,
  animationBegin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasAnimation: PropTypes.bool,
  idPrefix: PropTypes.string,
  isSample: PropTypes.bool,
  hideNoIndexArrow: PropTypes.bool,
  hideArrowLine: PropTypes.bool,
  customChartHeight: PropTypes.number,
  dealerPriceIndex: PropTypes.number,
  dealerPrice: PropTypes.number,
  showPolyline: PropTypes.bool,
  customLabelClassName: PropTypes.string,
  customPriceLabelClassName: PropTypes.string,
  svgRole: PropTypes.string,
  creativeId: PropTypes.string,
  trackingId: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  pricingChartDescription: PropTypes.string,
  isStepGradient: PropTypes.bool,
};

PricingChart.defaultProps = {
  className: 'text-center',
  isMobile: false,
  dataPoints: [1, 2, 4, 6, 7, 9, 12, 14, 11, 12, 14, 11, 12, 8, 7, 6, 6, 5, 10],
  columnWidth: { mobile: 16, desktop: 27 },
  columnPadding: 3,
  strokeLength: { desktop: 17, mobile: 17 },
  yRate: 5,
  yStartingPoint: 150,
  arrowSize: 12,
  arrowLineTopSpace: 15,
  arrowLineTopPosition: 0.5,
  customLabels: [],
  customPriceLabels: [],
  overlayConfig: null,
  selectedPriceIndex: null,
  selectedPricePercent: null,
  animationBegin: 'indefinite',
  hasAnimation: false,
  idPrefix: '',
  isSample: false,
  hideNoIndexArrow: true,
  hideArrowLine: false,
  customChartHeight: null,
  dealerPriceIndex: null,
  dealerPrice: null,
  showPolyline: false,
  customLabelClassName: 'medium text-lowercase',
  customPriceLabelClassName: 'heading-6',
  svgRole: 'img',
  creativeId: undefined,
  trackingId: undefined,
  ariaDescribedBy: undefined,
  pricingChartDescription: undefined,
  isStepGradient: false,
};
